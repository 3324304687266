<script lang="tsx">
import type { PropType, SlotsType } from 'vue'
import {
    type CheckboxPrimitiveValue,
    type CheckboxValue
} from '@core/app/composables/components'
import type { BaseFormElementProps } from '@core/app/utils/form'
import type { FormFieldObject } from '@core-types/form'

export type BaseUiCheckboxProps = {
    /**
     * The type of the input element.
     * @default 'checkbox'
     */
    type?: 'checkbox' | 'radio'
    /**
     * Value for radio input (if applicable)
     * Or checked flag for checkboxes. When this prop is provided,
     * the v-model value is not required.
     */
    value?: NonNullable<CheckboxPrimitiveValue>
    name?: string
    /**
     * Whether it is possible to uncheck radio inputs.
     */
    clearable?: boolean
} & Omit<
    BaseFormElementProps<CheckboxValue>, 'modelModifiers' | 'formModifiers'
>

export const getBaseUiCheckboxRuntimeProps = <TProps extends BaseUiCheckboxProps>(options?: ComponentOverrideOptions<any, TProps>)
: RuntimeProps<Pick<TProps, keyof BaseUiCheckboxProps>> => {
    const { modelModifiers, formModifiers, ...BaseFormElementRuntimeProps } = getBaseFormElementRuntimeProps([Boolean, String, Number, Array] as PropType<BaseUiCheckboxProps['modelValue']>, options)
    return {
        ...defineRuntimeProps<BaseUiCheckboxProps>({
            // @ts-ignore
            type: { type: String, default: 'checkbox' },
            value: { type: [Boolean, String, Number] },
            name: { type: String },
            clearable: { type: Boolean },
        }, options),
        ...BaseFormElementRuntimeProps,
    }
}

type BaseUiCheckboxSlots = {
    checkbox: { isChecked: boolean }
    radio: { isChecked: boolean }
}

export interface BaseUiCheckboxExposed {
    focus: () => void
}

type ComponentOptions = {}

export function defineComponentBaseUiCheckbox<T>(_options?: ComponentOverrideOptions<ComponentOptions, BaseUiCheckboxProps, BaseUiCheckboxSlots>) {
    return defineComponent(
        (props: BaseUiCheckboxProps, ctx) => {
            const { injected } = useCoreUiFormProvide<any>()

            let radioGroupInjected: ReturnType<typeof useCoreProvideRadioGroupProvide> | undefined
            if (props.type === 'radio') {
                radioGroupInjected = useCoreProvideRadioGroupProvide()
            }

            const isRadioAndIsInGroup = props.type === 'radio' && (radioGroupInjected?.injected?.inputValue !== undefined || radioGroupInjected?.injected?.formInputValue !== undefined)

            const inputValue = computed<CheckboxValue | undefined>({
                get() {
                    // in case of a radio input, we prioritize the radio group model value
                    if (isRadioAndIsInGroup) {
                        return radioGroupInjected!.injected?.inputValue.value
                    }
                    return props.modelValue
                },
                set(val) {
                    // in case of a radio input, we prioritize the radio group model value
                    if (isRadioAndIsInGroup) {
                        if (radioGroupInjected!.injected!.inputValue.value !== undefined) {
                            radioGroupInjected!.injected!.inputValue.value = val as (string | number | null | undefined)
                        }
                    }
                    ctx.emit('update:modelValue', val!)
                },
            })

            const formInputValue = computed<FormFieldObject<CheckboxValue> | undefined>({
                get() {
                    // in case of a radio input, we prioritize the radio group model value
                    if (isRadioAndIsInGroup) {
                        return radioGroupInjected!.injected?.formInputValue.value
                    }
                    return props.form
                },
                set(val) {
                    // in case of a radio input, we prioritize the radio group model value
                    if (isRadioAndIsInGroup) {
                        // @ts-expect-error
                        radioGroupInjected.injected.formInputValue.value = val
                    }
                    ctx.emit('update:form', val!)
                },
            })

            const describedBy = computed<string | undefined>(() => {
                if (!props.descriptionId) return
                return Array.isArray(props.descriptionId) ? props.descriptionId.join(' ') : props.descriptionId
            })

            const inputId = computed(() => props.id)


            const internalValue = computed<CheckboxValue | undefined>({
                get() {
                    // make normal `v-model` have higher priority
                    if (inputValue.value !== undefined) return inputValue.value
                    // in case of no `v-model` & a provided `value` for a checkbox, use it
                    // TODO: add more types like 'switch', etc. later -> need to update this implementation in BaseUiFormCheckbox too
                    if (props.value !== undefined && ['checkbox'].includes(props.type!) && formInputValue?.value?.__v === undefined) return props.value
                    // otherwise use the form input value binding
                    if (formInputValue.value === undefined && import.meta.dev) {
                        errorLog(...[`[BaseUiCheckbox]: no v-model value provided${isRadioAndIsInGroup ? ' for radio button group' : ''}`, getCurrentInstance()?.vnode.el].filter(Boolean))
                    }

                    // return null for radio buttons so that they can be checked if none is checked
                    return formInputValue.value?.__v ?? (props.type === 'radio' ? null : undefined)
                },
                set(value) {
                    inputValue.value = props.type === 'radio' ? value ?? null : value ?? false
                    if (formInputValue.value === undefined) return
                    formInputValue.value.__v = props.type === 'radio' ? value ?? null : value ?? false
                },
            })

            const isInputChecked = computed<boolean>(() => {
                if (props.type === 'radio') {
                    return internalValue.value === props.value
                }

                if (typeof internalValue.value === 'boolean') {
                    return internalValue.value
                }

                if (Array.isArray(internalValue.value)) {
                    return internalValue.value.includes(props.value!)
                }

                return internalValue.value === props.value && internalValue.value !== undefined
            })

            watch(isInputChecked, (val) => {
                if (val) ctx.emit('checked')
                else ctx.emit('unchecked')
            })

            /**
             * Toggles the checkbox value.
             * If the checkbox is disabled, this function does nothing.
             * @private
             */
            async function toggleCheckboxValue() {
                if (isNativeElementDisabled.value) return

                if (props.type === 'radio') {
                    // if the clearable prop is set, and we have a value, clear it
                    if (isInputChecked.value && props.clearable) {
                        internalValue.value = null
                    }
                    // set the value of the radio, do not toggle like checkbox
                    else if (props.value !== undefined) {
                        internalValue.value = props.value
                    }
                } else {
                    // checkbox boolean value
                    if (typeof internalValue.value === 'boolean') {
                        internalValue.value = !internalValue.value
                    }
                    // checkbox array selection
                    else if (Array.isArray(internalValue.value) && props.value !== undefined) {
                        if (internalValue.value.includes(props.value)) {
                            internalValue.value = internalValue.value.filter(v => v !== props.value)
                        } else {
                            internalValue.value = [...internalValue.value, props.value]
                        }
                    }
                }

                // call the change event handler
                await handleInputChange()
                handleFormErrorReset()
            }

            function handleFormErrorReset() {
                if (!injected.resetFormError || !formInputValue.value) return
                injected.resetFormError(formInputValue.value.__f)
            }

            async function handleInputChange() {
                ctx.emit('change', internalValue.value as CheckboxValue)

                if (!injected.bus || !formInputValue.value) return

                // TODO: check if necessary
                await nextTick()

                injected.bus.emit({
                    type: 'change',
                    __f: formInputValue.value.__f,
                })
            }

            async function handleInputBlur() {
                if (!injected.bus || !formInputValue.value) return

                // TODO: check if necessary
                await nextTick()

                injected.bus.emit({
                    type: 'blur',
                    __f: formInputValue.value.__f,
                })
            }

            const isDisabled = computed<boolean>(() => radioGroupInjected?.injected?.disabled === true || !!(props.disabled || props.loading))
            const isDisabledBecauseOfHydration = ref<boolean>(true)
            onMounted(() => {
                isDisabledBecauseOfHydration.value = false
            })

            const isNativeElementDisabled = computed<boolean>(() => internalValue.value === undefined || isDisabledBecauseOfHydration.value || isDisabled.value)

            const inputEl = useTemplateRef<HTMLInputElement>('inputEl')

            function focus() {
                inputEl.value?.focus()
            }

            ctx.expose({
                focus,
            } satisfies BaseUiCheckboxExposed)

            return () => (
                <div
                    class={['sim-checkbox', {
                        'sim-checkbox--checked': isInputChecked.value,
                        // Do not use isNativeElementDisabled here, because
                        // we only want to show the disabled style when
                        // explicitly set by the user
                        'sim-checkbox--disabled': isDisabled.value,
                        'sim-checkbox--loading': props.loading,
                        'sim-checkbox--error': props.ariaInvalid,
                    }]}
                >
                    <input
                        ref="inputEl"
                        id={inputId.value}
                        class='sim-checkbox__input'
                        type={props.type === 'radio' ? 'radio' : 'checkbox'}
                        name={radioGroupInjected?.injected?.name ?? props.name}
                        value={props.type === 'radio' ? props.value : undefined}
                        checked={isInputChecked.value}
                        required={radioGroupInjected?.injected?.formInputValue.value?.__r ?? props.required}
                        disabled={isNativeElementDisabled.value}
                        aria-describedby={describedBy.value}
                        aria-invalid={props.ariaInvalid}
                        aria-label={props.ariaLabel}
                        onChange={() => toggleCheckboxValue()}
                        onBlur={handleInputBlur}
                    />

                    <div class={['sim-checkbox__custom', {
                        'checkbox': props.type === 'checkbox',
                        'radio': props.type === 'radio',
                    }]}>
                        {
                            props.type === 'checkbox'
                                ? renderSlot(ctx.slots.checkbox, _options?.slots?.checkbox, {
                                    isChecked: isInputChecked.value,
                                }, (
                                    <>
                                        { isInputChecked.value ? 'X' : null }
                                    </>
                                ))
                                : renderSlot(ctx.slots.radio, _options?.slots?.radio, {
                                    isChecked: isInputChecked.value,
                                }, (
                                    <>
                                        { isInputChecked.value ? 'X' : null }
                                    </>
                                ))
                        }
                    </div>
                </div>
            )
        },
        {
            props: getBaseUiCheckboxRuntimeProps(_options),
            slots: Object as SlotsType<BaseUiCheckboxSlots>,
            emits: {
                'update:modelValue': (val: CheckboxValue) => true,
                'update:form': (val: FormFieldObject<CheckboxValue>) => true,
                'checked': () => true,
                'unchecked': () => true,
                'change': (value: CheckboxValue) => true,
            },
        }
    )
}

export default defineComponentBaseUiCheckbox()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiCheckbox.scss" as *;

</style>
