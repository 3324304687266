<script lang="tsx">
import { defineComponentBaseUiCheckbox } from '@core/app/components/base/ui/BaseUiCheckbox.vue'

export default defineComponentBaseUiCheckbox({
    slots: {
        checkbox: ({ isChecked }) => isChecked
            ? <svg height="8" viewBox="-2 -1 13 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.99999 8.4375L0.312988 4.6875L1.68799 3.3125L3.99999 5.5625L9.31299 0.3125L10.688 1.6875L3.99999 8.4375Z"
                />
            </svg>
            : null,
        radio: ({ isChecked }) => isChecked
            ?
            <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill="currentColor" />
            </svg>
            : null,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiCheckbox" as *;

@include checkbox {
    width: 1.625rem;
    height: 1.625rem;

    border: hr-line();
    border-radius: $vut-border-radius;
    background-color: white;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    &:focus-within {
        border-radius: 0;
    }
}

@include checkbox--checked {
    color: $vut-c-secondary;
    border-color: $vut-c-border;
}

@include radio {
    width: 1.625rem;
    height: 1.625rem;

    border: 1px solid $vut-c-gray;
    border-radius: 100vw;
    background-color: white;
    color: $vut-c-secondary;

    display: flex;
    justify-content: center;
    align-items: center;
}

@include checkbox--focus-visible {
    border-radius: 0; // Adjusting because the focus outline appears visually more rounded when border-radius is set to 1px
}

</style>
