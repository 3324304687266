<script lang="tsx">
import { defineComponentBaseUiFormCheckbox } from '@core/app/components/base/ui/form/BaseUiFormCheckbox.vue'

export default defineComponentBaseUiFormCheckbox({
    props: {
        labelClass: {
            default: ({ isInputChecked }) => ({
                'vut-checkbox__label': true,
                'vut-checkbox__label--checked': isInputChecked,
            }),
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormCheckbox" as *;
@use "@core-scss/components/BaseUiFormGroup" as *;

@include label-wrapper {
    gap: 0;
}

.vut-checkbox__label {
    text-transform: uppercase;
    font-family: $vut-ff-secondary;
    line-height: 1rem;
    color: $vut-c-secondary;
    letter-spacing: $vut-letter-spacing-large;
}

.vut-checkbox__label--checked {
    color: $vut-c-black;
}

</style>
