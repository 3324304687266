<script lang="tsx">
import type { SlotsType, VNode } from 'vue'
import type { CheckboxValue } from '@core/app/composables/components'
import type { BaseUiCheckboxProps } from '../BaseUiCheckbox.vue'
import type { _BaseUiFormGroupProps, BaseUiFormGroupSlots } from './BaseUiFormGroup.vue'
import { BaseUiCheckbox, BaseUiFormGroup } from '#components'
import type { FormFieldObject } from '@core-types/form'
import { getBaseUiFormGroupRuntimeProps } from './BaseUiFormGroup.vue'
import { getBaseUiCheckboxRuntimeProps } from '../BaseUiCheckbox.vue'

type _BaseUiFormCheckboxProps = {
    labelClass?: string | ((ctx: { isInputChecked: boolean }) => string | Record<string, boolean>)
    inputClass?: string | ((ctx: { isInputChecked: boolean }) => string | Record<string, boolean>)
}

export type BaseUiFormCheckboxProps = _BaseUiFormCheckboxProps & BaseUiCheckboxProps & Omit<
    _BaseUiFormGroupProps, 'noLabelElement' | 'descriptionId'
>

type BaseUiFormCheckboxSlots<T> = Omit<BaseUiFormGroupSlots, 'default'> & {
    'default': {}
    'below-label': {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormCheckbox<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormCheckboxProps, BaseUiFormCheckboxSlots<T>>) {

    const { noLabelElement, descriptionId, ...BaseUiFormGroupRuntimeProps } = getBaseUiFormGroupRuntimeProps(options)

    return defineComponent(
        (props: BaseUiFormCheckboxProps, ctx) => {

            let radioGroupInjected: ReturnType<typeof useCoreProvideRadioGroupProvide> | undefined
            if (props.type === 'radio') {
                radioGroupInjected = useCoreProvideRadioGroupProvide()
            }

            const inputValue = computed(() => radioGroupInjected?.injected
                ? radioGroupInjected.injected.inputValue.value
                : props.modelValue
            )

            const formInputValue = computed(() =>
                radioGroupInjected?.injected
                    ? radioGroupInjected.injected.formInputValue.value
                    : props.form
            )

            const internalValue = computed(() => {
                // make normal `v-model` have higher priority
                if (inputValue.value !== undefined) return inputValue.value
                // in case of no `v-model` & a provided `value`for a checkbox, use it
                // TODO: add more types like 'switch', etc. later
                if (props.value !== undefined && ['checkbox'].includes(props.type!) && formInputValue?.value?.__v === undefined) return props.value
                // otherwise use the form input value binding
                return formInputValue.value?.__v
            })

            const isInputChecked = computed(() => {
                if (props.type === 'radio') {
                    return internalValue.value === props.value
                }

                if (typeof internalValue.value === 'boolean') {
                    return internalValue.value
                }

                if (Array.isArray(internalValue.value)) {
                    return internalValue.value.includes(props.value!)
                }

                return internalValue.value === props.value && internalValue.value !== undefined
            })

            return () => (
                <BaseUiFormGroup
                    form={radioGroupInjected?.injected?.formInputValue.value ?? props.form}
                    help={props.help}
                    descriptionAbove={props.descriptionAbove}
                    descriptionBelow={props.descriptionBelow}
                    hideRequired={props.type === 'radio' ? true : props.hideRequired}
                    label={props.label}
                    noLabelElement={undefined}
                    errorId={props.errorId}
                    errorMessage={props.errorMessage}
                    descriptionId={props.descriptionId}
                    disabled={radioGroupInjected?.injected?.disabled ?? props.disabled}
                    loading={props.loading}
                    required={props.required}
                    id={props.id}
                    ariaInvalid={props.ariaInvalid}
                    fullWidth={props.fullWidth}
                >
                    {(slotData: BaseUiFormGroupSlots['default']) => [

                        slotData.renderAboveDescription(),

                        (
                            <div class={['sim-form-checkbox__container', {
                                'sim-form-checkbox__container--dsc-blw': !!props.descriptionBelow,
                            }]}>
                                <BaseUiCheckbox
                                    modelValue={props.modelValue}
                                    onUpdate:modelValue={(val: CheckboxValue) => ctx.emit('update:modelValue', val)}
                                    form={radioGroupInjected?.injected?.formInputValue.value ?? props.form}
                                    onUpdate:form={(val: FormFieldObject<CheckboxValue>) => ctx.emit('update:form', val)}

                                    class={[
                                        '[grid-area:input]',
                                        typeof props.inputClass === 'function'
                                            ? props.inputClass({ isInputChecked: isInputChecked.value })
                                            : props.inputClass,
                                    ]}
                                    type={props.type}

                                    id={slotData.inputId}
                                    value={props.value}
                                    name={props.name}
                                    clearable={props.clearable}
                                    disabled={props.disabled}
                                    loading={props.loading}
                                    required={slotData.isInputRequired}
                                    descriptionId={slotData.inputDescriptionIds}
                                    ariaLabel={props.ariaLabel}
                                    ariaInvalid={slotData.isInputAriaInvalid}

                                    onChange={(value: CheckboxValue) => ctx.emit('change', value)}
                                    onChecked={() => ctx.emit('checked')}
                                    onUnchecked={() => ctx.emit('unchecked')}
                                />

                                {slotData.renderLabel(
                                    (
                                        renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                                            <>
                                                {props.label}
                                            </>
                                        ))
                                    ) as VNode,
                                    {
                                        textNormal: true,
                                        hasDefaultSlotContent: ctx.slots.default !== undefined || !!options?.slots?.default,
                                        class: [
                                            '!cursor-pointer [grid-area:label] sim-form-checkbox__label-part',
                                            typeof props.labelClass === 'function'
                                                ? props.labelClass({ isInputChecked: isInputChecked.value })
                                                : props.labelClass,
                                        ],
                                        slotBelow: ctx.slots['below-label'] !== undefined || !!options?.slots?.['below-label']
                                            ? renderSlot(ctx.slots['below-label'], options?.slots?.['below-label'], {}) as VNode
                                            : undefined,
                                    }
                                )}

                                {props.descriptionBelow && (
                                    <div class="[grid-area:dsc] sim-form-checkbox__label-part">
                                        {slotData.renderBelowDescription()}
                                    </div>
                                )}

                            </div>
                        ),

                        slotData.renderError(),

                    ]}
                </BaseUiFormGroup>
            )
        },
        {
            props: {
                ...defineRuntimeProps<_BaseUiFormCheckboxProps>({
                    // @ts-ignore
                    labelClass: { type: [String, Function] },
                    // @ts-ignore
                    inputClass: { type: [String, Function] },
                }, options),
                ...getBaseUiCheckboxRuntimeProps(options),
                ...BaseUiFormGroupRuntimeProps,
            },
            slots: Object as SlotsType<BaseUiFormCheckboxSlots<T>>,
            emits: {
                'update:modelValue': (val: CheckboxValue) => true,
                'update:form': (val: FormFieldObject<CheckboxValue>) => true,
                'checked': () => true,
                'unchecked': () => true,
                'change': (value: CheckboxValue) => true,
            },
        }
    )
}

export default defineComponentBaseUiFormCheckbox()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormCheckbox.scss" as *;

</style>
